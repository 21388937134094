<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-8">
        <sidebar v-if="toggleSidebar" class="main-sidebar"></sidebar>
        <v-app><router-view /></v-app>
      </div>
    </div>
  </div>
</template>
<style>
/* Color Variables*/

:root {
  --aqua: #17bebb;
  --aqua-alt-1: #68dddb;
  --aqua-alt-2: #a6f8f6;
  --aqua-alt-3: #cffffe;
  --aqua-alt-4: #edffff;

  --govex: #a15a95;
  --bcpi: #0072ce;

  --red: #b92943;
  --orange: #eb8613;
  --yellow: #f9c815;
  --green: #2b8f65;
  --blue: #0093ad;
  --purple: #a15a95;
  --purple-alt: #4a3b9f;

  --black: #000000;
  --very-dark-grey: #5b5b5b;
  --dark-grey: #646464;
  --grey: #797979;
  --grey-alt: #efefef;
  --light-grey: #b9b7b7;
  --very-light-grey: #e0e0e0;
  --super-light-grey: #f1f1f1;
  --white: #ffffff;
  --off-white: #f8f8f8;

  /* Testimonials */
  --light-purple: #efecff;
  --dark-purple: #403378;
}

/* Temporary / MVP-only CSS */
.search-container {
  display: none;
}

/* Site wide CSS */
/* CSS stored here should be generalized, and not page specific */

body {
  margin: 0px;
  background-color: #f6f6f6;
}
.v-application--wrap {
  min-height: 100% !important;
}
html {
  overflow: scroll;
  overflow-x: hidden;
}
::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}

div {
  font-family: "roboto";
}

.box-half {
  display: inline-block;
  width: 50%;
}
.box-quarter {
  display: inline-block;
  width: 25%;
}
.box-three-quarters {
  display: inline-block;
  width: 75%;
}
.box-third {
  display: inline-block;
  width: 30%;
}
.box-two-thirds {
  display: inline-block;
  width: 60%;
}

.box-eigth {
  display: inline-block;
  width: 12.5%;
}

.box-three-eigths {
  display: inline-block;
  width: 37%;
}

.box-seven-eigths {
  display: inline-block;
  width: 87.5%;
}

.box-fifth {
  display: inline-block;
  width: 20%;
}

.box-two-fifth {
  display: inline-block;
  width: 40%;
}

.box-three-fifth {
  display: inline-block;
  width: 60%;
}

.box-four-fifth {
  display: inline-block;
  width: 80%;
}

.box-container {
  display: flex;
}

h1,
h2,
h3,
h4,
h5,
p,
table {
  font-family: "roboto";
}
h1.name {
  font-size: 1.5em;
}
h2 {
  font-size: 22px;
}
h3 {
  font-size: 18px;
}
h4 {
  font-size: 16px;
}
h5 {
  font-size: 14px;
}
p {
  font-size: 16px;
}

a {
  color: #0093ad;
}
a strong,
strong {
  font-family: "roboto-bold";
}

@font-face {
  font-family: "arvo";
  src: url("./assets/fonts/Arvo-Bold.ttf");
}
@font-face {
  font-family: "roboto";
  src: url("./assets/fonts/Roboto-Regular.ttf");
}
@font-face {
  font-family: "roboto-bold";
  src: url("./assets/fonts/Roboto-Bold.ttf");
}
.content-box {
  box-shadow: 0px 3px 4px 0px rgb(0, 0, 0, 10%);
  background-color: var(--white);
  border-radius: 6px;
  padding: 20px;
  margin: 30px 8px;
  margin-right: 30px;
}
div#projects-container,
.gepi-items-container {
  display: flex;
  flex-flow: row wrap;
  gap: 20px 0px;
  justify-content: flex-start;
  align-content: stretch;
}

div#projects-container .content-panel .panel-title,
.gepi-items-container .content-panel .panel-title {
  line-height: initial;
  font-size: 16px !important;
  font-weight: 600 !important;
}

div#projects-container .content-panel,
.gepi-items-container .content-panel {
  min-height: initial;
}

div#projects-container .content-panel .v-card__text,
.gepi-items-container .content-panel .v-card__text {
  display: inline-block;
  min-height: 80px;
}
.gepi-items-container .partner-card .flex-column {
  align-items: center !important;
}
.content-panel {
  font-family: "roboto-bold";
  box-shadow: 0px 3px 4px 0px rgb(0, 0, 0, 10%);
  background-color: var(--white);
  border-radius: 6px;
  padding: 0px;
  margin: 5px 10px;
  margin-right: 10px;
  min-width: 240px;
}
.content-panel.projects {
  margin: 0px 20px 30px 0px;
}
.panel-title {
  padding: 15px;
  text-align: center;
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1; */
}

.panel-content-head {
  padding: 0px 15px;
  text-align: left;
  display: inline;
  color: #505050;
  font-family: "roboto-bold";
}
.panel-content {
  font-family: "roboto";
  padding: 0px;
  text-align: left;
  display: inline;
  color: #646464;
}
.panel-line {
  padding: 15px 10px;
}
.content-box.associated-pin {
  padding: 0px 0px 5px 0px;
}

.associated-pin .pin-title {
  padding: 13px;
  font-family: "roboto-bold";
  font-size: 1.1em;
}
.pin-title {
  margin: 0px;
  background-color: var(--very-light-grey);
  color: var(--black);
  border-radius: 6px 6px 0px 0px;
  text-align: center;
}
.pin-list {
  font-family: "roboto";
  margin: 0px 15px;
}
.center,
.table-label.center {
  text-align: center;
}

.link {
  text-decoration: underline;
  color: #0093ad;
}
.button-aqua {
  background-color: var(--aqua);
  color: var(--white);
}
.display-number {
  font-weight: bold;
  font-size: 20px;
}
.activity {
  margin: 30px 10px;
}
.activity-stat {
  font-size: 2em;
  font-family: "roboto-bold";
}
#contacts {
  margin-top: 0px;
}

.testimonials .panel-title {
  background: var(--very-light-grey);
  color: var(--black);
  font-family: "roboto-bold";
}
.testimonial-button {
  color: white !important;
  text-align: center !important;
  background-color: #7c70b0;
  box-shadow: 2px 2px 2px 0px var(--light-grey);
  margin: 35px auto 10px auto;
  padding: 10px 50px;
  display: block;
}
.testimonial-button:hover {
  transform: translateY(-3px);
  webkit-transform: translateY(-3px);
  -webkit-transition: transform 250ms ease;
  transition: transform 250ms ease;
}
.testimonials .detail {
  margin: 10px;
}
.testimonial-box {
  display: inline-block;
}
.testimonial-other {
  width: 30%;
}
.testimonial-other .testimonial-button {
  width: 145px !important;
}

.content-panel.testimonial-other {
  width: 90% !important;
  min-width: 250px;
}

.button .button-title,
.button .button-icon {
  display: inline-block;
}

.button-icon {
  max-width: 30px;
  float: right;
}

.table-icon {
  height: 20px;
  position: absolute;
}

.add-button {
  background-color: var(--aqua);
  border: none;
  color: var(--white);
  padding: 10px 12px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  transition-duration: 0.4s;
  cursor: pointer;
  border-radius: 6px;
  color: var(--white);
  box-shadow: 2px 2px 2px 0px rgba(148, 148, 148, 30%);
  margin-top: 10px;
}

.add-button:hover {
  background-color: #1fdad6;
}

.remove-button,
.save-button {
  background-color: var(--aqua);
  border: none;
  color: var(--white);
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  transition-duration: 0.4s;
  cursor: pointer;
  border-radius: 6px;
  color: var(--white);
  box-shadow: 2px 2px 2px 0px rgba(148, 148, 148, 30%);
  margin: 0;
  height: 35px;
  width: 35px;
}

.remove-button:hover,
.save-button:hover {
  background-color: var(--aqua-alt-1);
}

.removebutton img,
.save-button img {
  padding-top: 5px;
}

.no-underline {
  text-decoration: none;
}

.project-panel {
  min-height: 200px;
  border-radius: 6px;
}

.project-panel:hover {
  cursor: pointer;
  transform: translateY(-3px);
  webkit-transform: translateY(-3px);
  -webkit-transition: transform 250ms ease;
  transition: transform 250ms ease;
}
label.v-label.theme--light {
  font-weight: 500;
}
/* Table formatting*/

.table-label {
  font-family: "roboto-bold";
  cursor: pointer;
  text-align: left;
  padding: 20px 20px;
  border-bottom: solid 1px #dbdbdb;
  color: rgb(58, 58, 58);
}

.table-item {
  padding: 20px 20px;
  color: #7b7b7b;
}

.table-item.table-link {
  font-family: "roboto-bold";
  text-decoration: underline;
  color: #0093ad;
}

.table-row:nth-child(even) {
  background-color: var(--off-white);
}

.display-table {
  width: 100%;
  max-width: -webkit-fill-available;
  box-shadow: 0px 3px 4px 0px rgb(0, 0, 0, 10%);
  background-color: var(--white);
  border-radius: 6px;
  padding: 20px;
  margin: 30px 10px;
  padding: 0px;
  border-collapse: collapse;
}

.display-table.detail-page-table {
  margin: 30px 30px 30px 0px;
}

.table-label.project-status-bar,
.table-item.project-status-bar {
  min-width: 200px;
}

/* Style tab links */
.tablink {
  background-color: transparent;
  color: #878787;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  font-size: 17px;
  width: 50%;
  font-family: "roboto-bold";
  border-bottom: solid 2px #b4b4b4;
}
.tablink.tablinkhalf {
  width: 50%;
}
.tablink.tablinkthird {
  width: 33.33%;
}
.tablink.active {
  color: var(--aqua);
  border-bottom: solid 2px;
}

.tablink:hover {
  background-color: var(--aqua);
  color: var(--white);
  border-bottom: solid 2px var(--aqua);
}

.pagination {
  display: inline-flex;
  float: right;
  margin-right: 30px;
  margin-bottom: 30px;
}
.pagination a {
  font-family: "roboto";
  margin: 0px 5px;
  color: var(--grey);
  cursor: pointer;
}

.search-options {
  margin: 0px 10px;
  font-family: "roboto";
}

label {
  display: flex;
  font-size: 16px;
  font-weight: bold;
}

select {
  height: 35px;
  margin: 10px 10px 10px 0px;
  font-family: "roboto";
  color: var(--grey);
  border: 1px solid var(--very-light-grey);
  border-radius: 6px;
  padding: 5px;
}

textarea {
  font-family: "roboto";
}

.input-box {
  border: 1px solid rgb(236, 236, 236);
  border-radius: 4px;
  box-sizing: border-box;
  width: 50%;
  padding: 10px 20px;
  margin: 10px 10px 10px 0px;
}

.input-box:focus {
  outline: solid 1px #cfcfcf;
}

.input-box::placeholder {
  color: rgb(163, 163, 163);
}

.search-type {
  display: flex;
  margin-top: 10px;
}

.search-type h2 {
  margin: 30px 20px;
  font-size: 36px;
  font-family: "roboto-bold";
}

.content-panel,
.project-panel {
  padding: 0px 0px 10px 0px;
}
a.project-panel {
  text-decoration: none;
}

.panel-title {
  border-radius: 6px 6px 0px 0px;
}
.project-panel .panel-title {
  font-family: "roboto-bold";
}
.projects .detail {
  margin: 15px 10px;
}
.project-panel .statusOnTrack,
.panel-title.update-status.statusOnTrack {
  background-color: var(--green);
  color: var(--white);
}
.project-panel .statusAtRisk,
.panel-title.update-status.statusAtRisk {
  background-color: var(--yellow);
  color: var(--black);
}
.project-panel .statusOffTrack,
.panel-title.update-status.statusOffTrack {
  background-color: var(--red);
  color: var(--white);
}
.results-count {
  font-size: 14px;
  color: var(--grey);
}
.results-count strong {
  font-family: "roboto-bold";
}
.detail {
  color: var(--dark-grey);
  margin: 5px 0px;
}
.detail strong {
  font-family: "roboto-bold";
}
.detail.detail-header span {
  color: var(--black) !important;
}

.detail.detail-header {
  margin-top: 0px;
}
.detail.org-address p {
  margin: 0px;
  font-size: 13px;
}

.detail.org-address {
  margin-top: 10px;
}
.pin-detail {
  color: var(--dark-grey);
  margin: 5px 0px;
  display: flex;
}
.pin-detail strong {
  font-family: "roboto-bold";
}
.pin-text {
  font-size: 13px;
  padding-top: 15px;
}
ul.project-orgs {
  list-style: none;
  padding: 0px !important;
}

ul.project-orgs li {
  display: block;
  line-height: 15px;
}

/* Style the tab content (and add height:100% for full page content) */
.tabcontent {
  color: var(--black);
  display: inline-block;
  padding: 30px 20px;
  height: max-content;
  width: 100%;
}
div.content {
  margin-left: 250px;
  padding: 1px 40px;
  height: 100%;
  background-color: #f6f6f6;
}

.center-dropdown {
  width: 110px;
}

/* Auto complete formatting */

.autocomplete-results {
  border: 1.5px solid var(--very-light-grey);
  overflow: auto;
  background-color: #ffffff;
  color: #595959;
  height: 150px;
  margin: -12px 0px 0px 0px;
  font-family: "roboto";
  border-radius: 0px 0px 6px 6px;
  padding: 2px 0px 0px 0px;
  width: 89.5%;
}

.autocomplete-result {
  list-style: none;
  text-align: left;
  padding: 7px 15px;
  cursor: pointer;
}

.autocomplete-result:hover {
  background-color: #dadada;
  color: rgb(15, 15, 15);
}

.auto-box .modal-text:focus {
  border-radius: 6px 6px 0px 0px;
}

.v-chip.tag-chip {
  margin-left: 0px !important;
}
.tag-chip {
  color: white !important;
  font-weight: bold;
  background-color: #aaaaaa !important;
  display: block;
}
.title-box .v-chip:first-of-type {
  margin-left: 0px !important;
}
.govex-chip {
  background-color: var(--govex) !important;
}
.bcpi-chip {
  background: var(--bcpi) !important;
}
.completed-chip {
  background: #3f3f3f !important;
}
.subgrant-chip {
  background: var(--yellow) !important;
  color: var(--black) !important;
}
.priority-area-chip,
.practice-chip {
  background: var(--very-light-grey) !important;
  color: var(--very-dark-grey) !important;
}

/* .chip-innovation,
.chip-analytics,
.chip-research,
.chip-learningdelivery,
.chip-servicedelivery,
.chip-civicengagement,
.chip-digital,
.chip-futurism,
.chip-bpcda,
.chip-communications,
.chip-academic {
  background: var(--grey) !important;
  color: var(--white) !important;
} */

.chip-innovation,
.chip-analytics,
.chip-research,
.chip-learningdelivery,
.chip-servicedelivery,
.chip-civicengagement {
  color: var(--black) !important;
}
.chip-digital,
.chip-futurism,
.chip-bpcda,
.chip-communications,
.chip-academic {
  color: var(--white) !important;
}
.chip-innovation {
  background: #f1bad6 !important;
}
.chip-digital {
  background: var(--grey) !important;
}
.chip-futurism {
  background: #e17d5d !important;
}
.chip-analytics {
  background: #fbde74 !important;
}
.chip-research {
  background: #52cb99 !important;
}
.chip-learningdelivery {
  background: #2ed3cc !important;
}
.chip-servicedelivery {
  background: #61d8fb !important;
}
.chip-bpcda {
  background: #41a2e4 !important;
}
.chip-communications {
  background: #5d86d6 !important;
}
.chip-academic {
  background: #786ac8 !important;
}
.chip-civicengagement {
  background: #b8b7b7 !important;
}
tbody tr:nth-of-type(even) {
  background-color: #f8f8f8;
}

.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > th:not(.v-data-table__mobile-row) {
  border-bottom: thin solid rgba(0, 0, 0, 0.05);
}
.v-data-footer__select .v-select__slot {
  background: var(--white) !important;
}
.aqua-text {
  color: var(--aqua);
  display: inline;
  font-weight: bold;
  font-size: 20px;
}

.title-box {
  padding-top: 20px;
}

.title-page {
  padding: 10px 10px;
}
.project-title-card .box-eigth {
  min-width: fit-content;
}
.project-tag {
  height: 30px;
  width: 30px;
  margin: 0px auto;
  padding: 25px 10px 10px 10px;
  background-color: var(--aqua);
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  mask-image: url("./assets/icons/projects.svg");
}
.project-tag-box {
  margin: 7px 10px;
  border-radius: 100px;
  height: 45px;
  width: 45px;
  padding-top: 5px;
  max-height: 140px;
  max-width: 140px;
  background-color: var(--aqua-alt-3);
  min-width: 45px;
}

.program-tag {
  height: 30px;
  width: 30px;
  margin: 0px auto;
  padding: 25px 10px 10px 10px;
  background-color: var(--aqua);
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  mask-image: url("./assets/icons/programs.svg");
}
.program-tag-box {
  margin: 7px 10px;
  border-radius: 100px;
  height: 45px;
  width: 45px;
  padding-top: 5px;
  max-height: 140px;
  max-width: 140px;
  background-color: var(--aqua-alt-3);
  min-width: 45px;
}
div#program-description {
  font-size: 12px;
  margin-bottom: 10px;
}
span.generic-text {
  font-size: 14px !important;
  margin-bottom: 10px;
  color: var(--grey);
}
.award-tag {
  height: 30px;
  width: 30px;
  margin: 0px auto;
  padding: 25px 10px 10px 10px;
  background-color: var(--aqua);
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  mask-image: url("./assets/icons/awards.svg");
}
.award-tag-box {
  margin: 5px 10px;
  border-radius: 100px;
  height: 45px;
  width: 45px;
  padding-top: 5px;
  max-height: 140px;
  max-width: 140px;
  background-color: var(--aqua-alt-3);
  min-width: 45px;
}

.organization-tag {
  height: 30px;
  width: 30px;
  margin: 0px auto;
  padding: 25px 10px 10px 10px;
  background-color: var(--aqua);
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  mask-image: url("./assets/icons/organizations.svg");
}
.organization-tag-box {
  margin: 5px 10px;
  border-radius: 100px;
  height: 45px;
  width: 45px;
  padding-top: 5px;
  max-height: 140px;
  max-width: 140px;
  background-color: var(--aqua-alt-3);
  min-width: 45px;
}
div#program-top-info {
  display: inline-flex;
}
div#program-title-card {
  width: -webkit-fill-available;
}
div#program-associated-info {
  width: -webkit-fill-available;
  max-width: 450px;
  min-width: 250px;
  margin: auto;
}
.v-input__slot,
input#search {
  background: #fcfcfc !important;
}

.v-input--switch .v-input__slot {
  background: inherit !important;
}
.v-input--switch label {
  font-size: 13px;
  font-weight: 400;
}
.v-input.ml-16.mt-n4.v-input--hide-details.theme--light.v-text-field.v-text-field--is-booted.v-text-field--placeholder.v-select.v-autocomplete {
  background: #fcfcfc;
  border: 1px solid rgba(0, 0, 0, 0.39);
  border-radius: 3px !important;
  width: -webkit-fill-available;
}
.v-input.ml-16.mt-n4.v-input--hide-details.theme--light.v-text-field.v-text-field--is-booted.v-text-field--placeholder.v-select.v-autocomplete
  input {
  font-size: 12px;
}
.gepi-alert {
  margin-top: 10px;
}
div#gepi-centers-overview {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}

div#gepi-centers-overview .content-panel {
  display: inline-block;
  flex-basis: 300px;
  flex-grow: 1;
}

.half-card {
  width: 50% !important;
  flex-basis: 50%;
  display: inline-block;
}

.card-content {
  display: flex;
  flex-flow: row wrap;
}

.rounded-lg.white--text.v-card.v-sheet.theme--light {
  font-family: "roboto-bold";
  border-radius: 16px !important;
  height: 32px;
  padding-top: 5px;
  width: 80px;
  text-align: center;
}

/* Responsive formatting */
@media screen and (max-width: 750px) {
  .search-container {
    display: none;
  }
  div.content {
    margin-left: 0px;
  }
  .tablink {
    padding: 14px 4px;
    font-size: 14px;
  }
}
@media screen and (max-width: 1000px) {
  .box-half,
  .box-quarter,
  .box-third,
  .box-two-thirds,
  .box-fifth,
  .box-two-fifth,
  .box-three-fifth,
  .box-four-fifth,
  .box-three-quarters,
  .box-eigth,
  .box-three-eigths,
  .box-seven-eigths {
    width: 90%;
  }
  .box-half-icon,
  .box-quarter-icon {
    width: 40%;
  }
  .box-half-details {
    width: 60%;
    position: relative;
    top: -35px;
    display: inline-block;
  }
  .box-quarter-details {
    width: 60%;
    position: relative;
    display: inline-block;
  }

  .box-container {
    display: block;
  }
  .v-application .pl-16 {
    margin-left: -70px !important;
  }
  .close-button {
    position: absolute;
    top: 1px;
    right: 1px;
  }
  .v-data-table__mobile-row:nth-child(1) {
    font-weight: bold;
  }
}
</style>
<script>
import axios from "axios";
import Sidebar from "./components/Sidebar.vue";
export default {
  components: {
    sidebar: Sidebar,
  },
  computed: {
    toggleSidebar() {
      return (
        this.$route.name != "login" &&
        this.$route.name != "callback" &&
        this.$route.name != "bcpi-ta-map" &&
        this.$route.name != "bcpi-qr-map"
      );
    },
  },
};
</script>
