import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import titleMixin from "./mixins/title";
import moment from "moment";

import api from "@/common/api.config";
import vuetify from "./plugins/vuetify";
import VueGtag from "vue-gtag";

Vue.prototype.$api = api; // use api config globally in componenets

Vue.config.productionTip = false;

Vue.mixin(titleMixin);
Vue.mixin({
  methods: {
    formatField: function(model, field, code) {
      // Testimonial
      if (model.toLowerCase() == "testimonial") {
        if (field.toLowerCase() == "type") {
          switch (code) {
            case "DCM":
              return "Document";
            case "EML":
              return "Email";
            case "PDC":
              return "Podcast";
            case "QTE":
              return "Quote";
            case "VDO":
              return "Video";
          }
        }
      }
    },
  },
});

Vue.filter("formatDate", function(value) {
  if (value) {
    return moment(String(value)).format("MMM D, YYYY");
  }
});

Vue.filter("formatNull", function(value) {
  if (!value || value === "$0" || value === "") {
    return "-";
  } else {
    return value;
  }
});
Vue.filter("formatNull_None", function(value) {
  if (!value || value === "") {
    return "None";
  } else {
    return value;
  }
});

Vue.use(
  VueGtag,
  {
    config: {
      id: "G-QTHNVPD82X",
      enabled: process.env.NODE_ENV === "production", // Enable tracking only in production
    },
  },
  router
); // Provide the router instance to the VueGtag plugin

// Global beforeRouteEnter guard
router.beforeEach((to, from, next) => {
  Vue.$gtag.pageview(to.path); // Track the page view
  next();
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
